import React from 'react'
import  { useEffect , useState } from 'react';
import IMG from '../Logoo.png';
import { Link, useNavigate } from 'react-router-dom';

function Navbar() {
  const navigate = useNavigate();

  const handleNavClick = (event, href) => {
    if (href.startsWith('/#')) {
      event.preventDefault();
      navigate('/');
      setTimeout(() => {
        window.history.replaceState(null, null, href);
        window.dispatchEvent(new Event('hashchange'));
      }, 0);
    }
  };

  
    const [clicked, setClicked] = useState(false);
    const handleClick = () => {
        console.log("clicked")
        
            setClicked(!clicked);
            console.log("clicked", clicked)
          };

          
  return (
    <div class="page_wrapper">

    
      <header>
       
        <div class="container">
           
            <nav class="navbar navbar-expand-lg nav-resp" >
                <a class="navbar-brand" href="#">
        <img src={IMG} alt="image" />
      </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon">
         
          <div class="toggle-wrap">
            <span onClick={handleClick} class="toggle-bar"></span>
          </div>
        </span>
      </button>
      
      <div>
      {clicked && (
        <nav className="navbar-links">
          <div className="body-container">
            <div className="container">
              <div className="search-container">
                <input
                  id="search-bar"
                  type="text"
                  placeholder="searching"
                  autoFocus
                />
                <i className="ion-close"></i>
              </div>
            </div>
          </div>

          <ul className='center'>
            <li className="nav-item ">
              <a href="/" className="nav-link">Home</a>
            </li>
            <li className="nav-item">
            <Link to="/#features" class="nav-link" onClick={(e) => handleNavClick(e, '/#features')}>Features</Link>
                
             
            </li>
            <li className="nav-item">
            <Link to="/#how_it_work" onClick={(e) => handleNavClick(e, '/#how_it_work')} class="nav-link" >About Us</Link>
               
              
            </li>
            <li className="nav-item">
            <Link to="/#services" onClick={(e) => handleNavClick(e, '/#services')} class="nav-link">Services</Link>
            </li>
         
            <li className="nav-item">
            <Link class="nav-link" to="/Contactus">Contact</Link>
            </li>
            <li class="">
                            <button class="btn btn-primary" style={{borderRadius:"10px"}}> STARTED</button>
                        </li>
          </ul>
          <i className="ion-load-c"></i>
        </nav>
      )}
    </div>


                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto">
                       
                        <li class="nav-item">
                            <Link to="/" class="nav-link">Home</Link>
                          
                        </li>
                        {/* <li><Link to="/#features" onClick={(e) => handleNavClick(e, '/#features')}>Features</Link></li>
        <li><Link to="/#how_it_work" onClick={(e) => handleNavClick(e, '/#how_it_work')}>About Us</Link></li>
        <li><Link to="/#services" onClick={(e) => handleNavClick(e, '/#services')}>Services</Link></li>
        <li><Link to="/contactus">Contact</Link></li> */}
                       
                        <li class="nav-item ">
                            <Link to="/#features" class="nav-link" onClick={(e) => handleNavClick(e, '/#features')}>Features</Link>
                        </li>
                        <li class="nav-item">
                            <Link to="/#how_it_work" onClick={(e) => handleNavClick(e, '/#how_it_work')} class="nav-link" >About Us</Link>
                        </li>
                       
                        <li class="nav-item">
                            <Link to="/#services" onClick={(e) => handleNavClick(e, '/#services')} class="nav-link">Services</Link>
                           </li>
                        <li class="nav-item">
                            <Link class="nav-link" to="/Contactus">Contact</Link>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link dark_btn" href="/#getstarted">GET STARTED</a>
                        </li>
                    </ul>
                </div>
            </nav>
        
        </div>
     
    </header> 
    </div>
  )
}

export default Navbar