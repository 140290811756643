import React, { useEffect , useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight} from '@fortawesome/free-solid-svg-icons';
import { faAngleDown} from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { Container, Row, Col } from 'react-bootstrap';
import IMG from '../Logoo.png';
import imeg1 from '../imeg1.png';
import './Homepage.css'; 
import Navbar from '../Navbar/Navbar';
const Home=()=>{

  const [status, setStatus] = useState(true);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setStatus(false);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash) {
        const targetElement = document.querySelector(hash);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    // Scroll to section on initial load if there's a hash
    handleHashChange();

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);


const [counters, setCounters] = useState([]);

    useEffect(() => {
        const handleScroll = () => {
            const countersElements = document.querySelectorAll('.counter-value');
            countersElements.forEach(counterElement => {
                if (isInViewport(counterElement)) {
                    startCounters();
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const isInViewport = element => {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    const [showVideoModal, setShowVideoModal] = useState(false);
    const startCounters = () => {
        const updatedCounters = [];
        const countersElements = document.querySelectorAll('.counter-value');

        countersElements.forEach(counterElement => {
            const target = +counterElement.getAttribute('data-count');
            const speed = 200; // The lower the number, the faster the count
            let count = +counterElement.innerText;

            let inc = target / speed;

            if (target > 100) {
                inc = Math.ceil(target / speed);
            }

            if (count < target) {
                count += inc;
            } else {
                count = target;
            }

            updatedCounters.push({ ...counterElement, count });
        });

        setCounters(updatedCounters);
    };
    const handleClick = () => {
        console.log("clicked")
        
            setClicked(!clicked);
            console.log("clicked", clicked)
          };



          
  return (
    <>
<Navbar/>
<body>

{/* <!-- Page-wrapper-Start --> */}
<div class="page_wrapper">

    {/* <!-- Preloader --> */}
    {status? (<>
    <div id="preloader">
        <div id="loader"></div>
    </div>
    </>):(<>

    </>)}

    {/* <!-- Header Start --> */}
    {/* <header>
       
        <div class="container">
           
            <nav class="navbar navbar-expand-lg">
                <a class="navbar-brand" href="#">
        <img src={IMG} alt="image" />
      </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon">
         
          <div class="toggle-wrap">
            <span onClick={handleClick} class="toggle-bar"></span>
          </div>
        </span>
      </button>
      
      <div>
      {clicked && (
        <nav className="navbar-links">
          <div className="body-container">
            <div className="container">
              <div className="search-container">
                <input
                  id="search-bar"
                  type="text"
                  placeholder="searching"
                  autoFocus
                />
                <i className="ion-close"></i>
              </div>
            </div>
          </div>

          <ul className='center'>
            <li className="nav-item ">
              <a href="#" className="nav-link">Home</a>
            </li>
            <li className="nav-item">
              <a href="#features" className="nav-link">Features &nbsp;
                
              </a>
            </li>
            <li className="nav-item">
              <a href="#how_it_work" className="nav-link">How it works &nbsp;
               
              </a>
            </li>
            <li className="nav-item">
              <a href="#pages" className="nav-link">Pages</a>
            </li>
         
            <li className="nav-item">
              <a href="#contact" className="nav-link">Contact Us</a>
            </li>
            <li class="">
                            <button class="btn btn-primary" style={{borderRadius:"10px"}}> STARTED</button>
                        </li>
          </ul>
          <i className="ion-load-c"></i>
        </nav>
      )}
    </div>


                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto">
                       
                        <li class="nav-item">
                            <a class="nav-link" href="#">Home</a>
                          
                        </li>
                       
                        <li class="nav-item ">
                            <a class="nav-link" href="#features">Features</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#how_it_work">About Us</a>
                        </li>
                       
                        <li class="nav-item">
                            <a class="nav-link" href="#services">Services</a>
                           </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#contact">Contact</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link dark_btn" href="#getstarted">GET STARTED</a>
                        </li>
                    </ul>
                </div>
            </nav>
        
        </div>
     
    </header> */}

    {/* <!-- Banner-Section-Start --> */}

    <section class="banner_section">
        {/* <!-- hero bg --> */}
        <div class="hero_bg"> <img className="hero_img"  style={{ maxwidth: "100%",height: "auto", width: "100%",maxheight: "800px" }} src="images/hero-bg.png" alt="image"/> </div>
        {/* <!-- container start --> */}
        <div class="container" style={{marginTop:"-40px"}}>
            <div class="row " >
                <div class="col-lg-6 col-md-12 "  >
                  
                    <div class="banner_text" >
                   
                       <h1 style={{marginBottom:"20px"}}>Empowering Your<span> Phone Experience</span></h1> 
                       
                        {/* <!-- p --> */}
                        <p>If you are seeking the easiest means for mobile selection then ABC Mobile Phone LTD is the right place for you
                        </p>
                    </div>
                    <div class="trial_box">
                        {/* <!-- form --> */}
                        {/* <form action="" > */}
                            <div class="d-flex">
                                <input type="email" class="form-control" placeholder="Enter your email"/>
                                <button class="btn btn-primary" >SUBMIT</button>
                            </div>
                        {/* </form> */}
                    </div>

                    {/* <!-- list --> */}
                    <div class="trial_box_list">
                        <ul>
                            {/* <li><i class="fa fa-check"></i>1 Month free Warranty</li> */}
                            <li><i class="fa fa-check"></i>Wide Rage of Accessories</li>

                            {/* <li><i class="fa fa-check"></i> Monthly Payment Plans available</li> */}
                            <li><i class="fa fa-check"></i> Expert Phone Repair </li>

                        </ul>
                    </div>

                    {/* <!-- users --> */}
                    <div class="used_app">
                        <ul>
                            <li><img src="images/used01.png" alt="image"/></li>
                            <li><img src="images/used02.png" alt="image"/></li>
                            <li><img src="images/used03.png" alt="image"/></li>
                            <li><img src="images/used04.png" alt="image"/></li>
                        </ul>
                        <p>12M + <br/> Customer</p>
                    </div>
                </div>

                {/* <!-- banner images start --> */}
                <div class="col-lg-5 col-md-9">
            {/* <img  class="moving_animation" src="https://ik.imagekit.io/cy8phtesy/download__7_-removebg-preview%202.png?updatedAt=1715278540202images/bannerimage1.png" width={"280px"} alt="image" /> */}
    <div class="banner_images image_box1">
        <span class="banner_image1" >
            {/* <img  style={{ backgroundColor: "white", padding: "50px 0px 100px 40px", borderRadius:"20px", height:"300px", width:"250px"}} class="moving_animation" src="https://ik.imagekit.io/cy8phtesy/ezgif-4-2c5dd1f45b.png?updatedAt=1715597517981" width={"280px"} alt="image" /> */}
           <img  class="moving_animation"   style={{  height:"450px", width:"550px" }} src="https://ik.imagekit.io/cy8phtesy/file.png?updatedAt=1717004886982"   alt="image"  />
           
          
        </span>
        {/* <div class="banner_image2 mr-5 " >
            <img style={{}} class="moving_animation" src="https://ik.imagekit.io/cy8phtesy/Screenshot__13_-removebg-preview.png?updatedAt=1717002910027"  width={"380px"} height={"auto"} alt="image" />
        </div> */}
    </div>
</div>


                {/* <div class="col-lg-3 col-md-6"  >
                    <div class=" image_box2 " >
                    <div class="imageB"></div>
                    <div class="slider-container">
            <img class="moving_animationn" src="https://ik.imagekit.io/cy8phtesy/Screenshot__3_-removebg-preview.png?updatedAt=1716916866487" alt="image" />
       
                        <span class="banner_image3 " > <img class="moving_animationn mb-2"  src={imeg1} alt="image" /></span>
                        </div>
                    <div class="banner_image4 mt-3" style={{marginBottom:"5px"}}> <img style={{ borderRadius:"15px"}} class="moving_animation" src="https://ik.imagekit.io/cy8phtesy/Screenshot%20(2).png?updatedAt=1716914632164" alt="image" />
                  
                     </div>
                  
                    </div>
                   
                  
                    
                </div> */}
                {/* <!-- banner slides end --> */}

            </div>
            {/* <!-- row end --> */}
        </div>
        {/* <!-- container end --> */}
    </section>
    {/* <!-- Banner-Section-end --> */}

    {/* <!-- Features-Section-Start --> */}
    <section class="row_am features_section" id="features">
        {/* <!-- section bg --> */}
        <div class="feature_section_bg"> <img src="images/section-bg.png" alt="image"/> </div>
        {/* <!-- container start --> */}
        <div class="container" style={{ position: 'relative' }} >
            <div class="features_inner" style={{ paddingTop: '200px' }}>
                <div className="feature_img">
          <img className='image' src="https://ik.imagekit.io/cy8phtesy/download__51_-removebg-preview%202%20(1).png?updatedAt=1717004341600" alt="image"/>
        </div>

                <div class="section_title"  data-aos-duration="1500" data-aos-delay="300">
                    <h2><span>Discover</span> the latest smartphone!</h2>
                   <p> Trade-in your old ones for great value, and find quality accessories along with <br/>expert repair services at competitive prices.</p>                                         
                </div>

                {/* <!-- story --> */}
                <div class="features_block">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="feature_box"  data-aos-duration="1500">
                                <div class="image">
                                    <img src="images/secure_data.png" alt="image"/>
                                </div>
                                <div class="text">
                                    <h4>Latest Smartphones Available</h4>
                                    <p> Explore a wide range of the newest smartphones from top brands, all at competitive prices.
</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="feature_box"  data-aos-duration="1700">
                                <div class="image">
                                    <img src="images/functional.png" alt="image"/>
                                </div>
                                <div class="text">
                                    <h4>Trade-In Your Old Phone</h4>
                                    <p>Get the best value for your old device with our easy and hassle-free trade-in program.


</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="feature_box"    data-aos-duration="1900">
                                <div class="image">
                                    <img src="images/live-chat.png" alt="image"/>
                                </div>
                                <div class="text">
                                   
<h4>High-Quality Accessories</h4>
                                    <p>Enhance your mobile experience with our extensive selection of cases, chargers, screen protectors, and more.


 

</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="feature_box"    data-aos-duration="1900">
                                <div class="image">
                                    <img src="images/support.png" alt="image"/>
                                </div>
                                <div class="text">
                                <h4>Expert Repair Services</h4>
                                    <p>Trust our certified technicians for quick and reliable repair services, from screen replacements to battery issues.

</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

        
            </div>

        </div>
        {/* <!-- container end --> */}
    </section>
    {/* <!-- Features-Section-end --> */}

    {/* <!-- About-App-Section-Start --> */}
    <section class="row_am about_app_section">
        {/* <!-- container start --> */}
        <div class="container">
            {/* <!-- row start --> */}
            <div class="row align-items-center">
                <div class="col-lg-6 aos-init aos-animate " data-aos="fade-right" data-aos-duration="1500" >
{/* ><div><img class="moving_animation"   src="https://ik.imagekit.io/cy8phtesy/Screenshot__6_-removebg-preview.png?updatedAt=1716921983815"  height={"300px"} width={"auto"} alt="image"/>
</div */}
                    {/* <!-- about images --> */}
                    <div class="about_img"   data-aos-duration="1500">

<div class="frame_img" style={{ position: 'relative', width: '1000px', height: '600px',left: -40 }}>
    <img class="moving_animation" src="https://ik.imagekit.io/cy8phtesy/download__64_-removebg-preview%201.png?updatedAt=1717169525929" style={{ position: 'absolute', top: 10, left: 0, width: 'auto', zIndex: 1 , height:"550px"}} alt="image"/>
</div>
{/* <div class="screen_img mr-5"  style={{ position: 'absolute', top: '50%', left: '85%', transform: 'translate(-50%, -50%)', zIndex: 2 }}>
    <img class="moving_animation"   src="https://ik.imagekit.io/cy8phtesy/Screenshot__6_-removebg-preview.png?updatedAt=1716921983815"  height={"300px"} width={"auto"} alt="image"/>
</div> */}
</div>
                </div>
                <div class="col-lg-6">

                    {/* <!-- about text --> */}
                    <div class="about_text">
                        <div class="section_title"    data-aos-duration="1500" data-aos-delay="100">

                            {/* <!-- h2 --> */}
                            <h2> Trusted Mobile Solutions <span> Across the UK</span></h2>
                           


                            {/* <!-- p --> */}
                            <p>
                            For years, we've been delighting customers with our comprehensive mobile services, including the latest smartphones, top-quality accessories, and expert repairs. Our hassle-free trade-in program ensures customers across the UK receive the best value for their old devices.
                       
                       </p> </div>

                        {/* <!-- UL --> */}
                        <ul class="app_statstic aos-init aos-animate" id="counter" data-aos="fade-in"  data-aos-duration="1500">
                            <li>
                                <div class="icon">
                                    <img src="images/download.png" alt="image"/>
                                </div>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div class="text">
                                    <p><span class="counter-value" data-count="17">0</span><span>M+</span></p>
                                    <p>Download</p>
                                </div>
                            </li>
                            <li>
                                <div class="icon">
                                    <img src="images/followers.png" alt="image"/>
                                </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div class="text">
                                    <p><span class="counter-value" data-count="08">0 </span><span>M+</span></p>
                                    <p>Followers</p>
                                </div>
                            </li>
                            <li>
                                <div class="icon">
                                    <img src="images/reviews.png" alt="image"/>
                                </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div class="text">
                                    <p><span class="counter-value" data-count="2300">1500</span><span>+</span></p>
                                    <p>Reviews</p>
                                </div>
                            </li>
                            <li>
                                <div class="icon">
                                    <img src="images/countries.png" alt="image"/>
                                </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div class="text">
                                    <p><span class="counter-value" data-count="150">0</span><span>+</span></p>
                                    <p>Countries</p>
                                </div>
                            </li>
                        </ul>
                        {/* <!-- UL end --> */}
                        {/* <a href="contact.html" class="btn puprple_btn"   data-aos-duration="1500">START FREE TRIAL</a> */}
                    </div>
                </div>
            </div>
            {/* <!-- row end --> */}
        </div>
        {/* <!-- container end --> */}
    </section>
    {/* <!-- About-App-Section-end --> */}

    {/* <!-- ModernUI-Section-Start --> */}
    <section class="row_am modern_ui_section" id="services">
        {/* <!-- section bg --> */}
        <div class="modernui_section_bg"> <img src="images/section-bg.png" alt="image"/> </div>
        {/* <!-- container start --> */}
        <div class="container">
            {/* <!-- row start --> */}
            <div class="row">
                <div class="col-lg-6">
                    {/* <!-- UI content --> */}
                    <div class="ui_text">
                        <div class="section_title"    data-aos-duration="1500" data-aos-delay="100">
                            <h2>We Sell All Top  Brand<span> Smartphones</span></h2>
                       
                            <p>
                            we take pride in offering a wide selection of the latest smartphones from leading brands. Whether you're in search of cutting-edge technology, sleek design, or reliable performance, our collection has something for everyone. From flagship models to budget-friendly options, we strive to cater to diverse preferences and budgets. Our commitment to quality ensures that each smartphone meets the highest standards, providing you with a seamless and satisfying mobile experience. Explore our range today and find the perfect smartphone to suit your needs and style.
                            </p>  
                        </div>
                        <ul class="design_block">
                            <li    data-aos-duration="1500">
                                <h4> We are Competing in coverage</h4>
                                {/* <p>Lorem Ipsum is simply dummy text of the printing and type esetting industry lorem Ipsum has.</p> */}
                            </li>
                            <li    data-aos-duration="1500">
                                <h4> Save your money through buying a new Model outright</h4>
                                {/* <p>Simply dummy text of the printing and typesetting inustry lorem Ipsum has Lorem dollar summit.</p> */}
                            </li>
                            <li    data-aos-duration="1500">
                                <h4> Only Use High Quality Parts</h4>
                                {/* <p>Printing and typesetting industry lorem Ipsum has been the industrys standard dummy text of type setting. */}
                                {/* </p> */}
                            </li>
                            <li    data-aos-duration="1500">
                                <h4> Only Use High Quality Parts</h4>
                                {/* <p>Printing and typesetting industry lorem Ipsum has been the industrys standard dummy text of type setting. */}
                                {/* </p> */}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 mb-5">
                    {/* <!-- UI Image --> */}
                    <div class="ui_images"   data-aos-duration="1500">
                        <div class="left_img"

                        style={{ position: 'relative', width: '1000px', height: '600px',left: -40 }}>
                            <img class="moving_animation" src="https://ik.imagekit.io/cy8phtesy/OnePlus_Bullets_Z2_Bluetooth_Wireless_in_Ear_Earphones_with_Mic-removebg-preview%201.png?updatedAt=1715278540319"   style={{ position: 'absolute', top: 10, left: 0, width: '100%', zIndex: 1 }} alt="image"/>
                        </div>
                        <div class="screen_img mr-5"  style={{ position: 'absolute', top: '60%', left: '60%', transform: 'translate(-50%, -50%)', zIndex: 2 }}>
                            <img class="moving_animation"   src="https://ik.imagekit.io/cy8phtesy/download__5_-removebg-preview%201.png?updatedAt=1715289684257"  height={500} width={"auto"} alt="image"/>
                        </div>
                            {/* <img class="moving_animation" src="https://ik.imagekit.io/cy8phtesy/download__7_-removebg-preview%201.png?updatedAt=1715278540215" height="150%" width="100%" style={{borderRadius:"10px"}} alt="image"/> */}
                        </div>
                        {/* <!-- UI Image --> */}
                        {/* <div class="right_img  m-3"> */}
                            {/* <img class="moving_animation" src="images/shield_icon.png" alt="image"/> pahly wala*/}
                            {/* <img class="moving_animation" src="https://ik.imagekit.io/cy8phtesy/topbrend3-removebg-preview%20(1).png?updatedAt=1715191230753"  height={"50%"} width={"70%"} alt="image"/> */}
                            {/* <img class="moving_animation" src="https://ik.imagekit.io/cy8phtesy/latest_phones-removebg-preview.png?updatedAt=1715198397277" height={"190%"} width={"120%"} style={{marginTop:"-25%",borderRadius:"10px"}} alt="image"/>*/}
                        {/* </div>  */}
                 
                </div>
            </div>
            {/* <!-- row end --> */}
        </div>
        {/* <!-- container end --> */}
    </section>
    {/* <!-- ModernUI-Section-end --> */}

    {/* <!-- How-It-Workes-Section-Start --> */}
    <section class="row_am how_it_works" id="how_it_work">
        {/* <!-- section bg --> */}
        <div class="how_section_bg"> <img src="images/section-bg.png" alt="image"/> </div>
        {/* <!-- container start --> */}
        <div class="container">
        <div class="how_it_inner">
                <div class="section_title"    data-aos-duration="1500" data-aos-delay="300">
                    {/* <!-- h2 --> */}
                    <h2><span>How it works</span> - 3 easy steps</h2>
                    {/* <!-- p --> */}
                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typese tting <br/> indus orem Ipsum has beenthe standard dummy.</p> */}
                
					<p>	Here is how our process works you got a broken screen or any  <br/>issues with your phone and we repair it with fastest turn around time.</p>

					
                </div>
                <div class="step_block">
                    {/* <!-- UL --> */}
                    <ul>
                        {/* <!-- step --> */}
                        <li className="">
                            <div class="step_text "   data-aos-duration="1500">
                            <h4>Express Service</h4>
                                <p>Select from buying a new phone, Selling your old one, Or getting repairs.</p>
                            </div>
                            <div class="step_number number1 col-lg-1 col-md-1 col-sm-1">
                                <h3>01</h3>
                            </div>
                            <div class="step_img "   data-aos-duration="1500" >
                                 {/* <img src="images/download_app.jpg" alt="image"/>  */}
                                 <img  className='moving_animation' src="https://ik.imagekit.io/cy8phtesy/_removal.ai__109fd73f-322a-49d2-8e20-e8928d694b09-111.png?updatedAt=1717262875098" style={{height:"150px"}} alt="image"/>
                            </div>
                        </li>

                      
                        {/* <!-- step --> */}
                        <li className=''>
                            <div class="step_text "   data-aos-duration="1500">
                           <h4>Complete Transaction</h4>
                                {/* <span>14 days free trial</span> */}
                                <p>Follow the simple step to finalize your purchase, Trade-in, Or repair request.</p>
                            </div>
                            <div class="step_number number2 col-lg-1 col-md-1 col-sm-1">
                                <h3>02</h3>
                            </div>
                            <div class="step_img "   data-aos-duration="1500">
                                {/* <img src="images/create_account.jpg" alt="image"/> */}
                                <img className='moving_animation' src="https://ik.imagekit.io/cy8phtesy/_removal.ai__f9084b31-e999-4345-9415-414cd5ddc371-2222.png?updatedAt=1717262874982" alt="image"/>
                            </div>
                        </li>



                        {/* <!-- step --> */}
                        <li>
                            <div class="step_text"   data-aos-duration="1500">
                                <h4>Enjoy your device</h4>
                                {/* <span>Have any questions check our <a href="#">FAQs</a></span> */}
                                <p>Repair your new phone, cash for your old one, Or a fully repaired device quickly. </p>
                            </div>
                            <div class="step_number number3">
                                <h3>03</h3>
                            </div>
                            <div class="step_img"   data-aos-duration="1500">
                                {/* <img src="images/enjoy_app.jpg" alt="image"/> */}
                                <img className='moving_animation' src="https://ik.imagekit.io/cy8phtesy/_removal.ai__01a4192c-fbcf-4de8-a184-c4cef10b6bf7-3333.png?updatedAt=1717262875357" alt="image"/>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            {/* <!-- video section start --> */}
            <div class="yt_video"   data-aos-duration="1500" style={{border:showVideoModal ? "1px solid lightgrey" : "1px solid transparent"}}>

            {showVideoModal ? (
                <>
                <iframe style={{}} className={'youtube_vedio'} src="https://www.youtube.com/embed/0AiZkv8x8eA" title="2023 Galaxy A: Official Film | Samsung" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </>
            ):(
                <>
                <div class="thumbnil">
                    <img src="images/yt_thumb.jpg" alt="image" style={{height:"360px", width:"1400px", borderRadius:"13px"}}/>
                    
                    <a class="popup-youtube play-button" data-url="https://www.youtube.com/embed/0AiZkv8x8eA" data-toggle="modal" data-target="#myModal" title="XJj2PbenIsU">
          <span class="play_btn">
            <img src="images/play_icon.png" alt="image" onClick={()=>{
                setShowVideoModal(true);
                console.log("ab==")
                }} /> 
            <div class="waves-block">
              <div class="waves wave-1"></div>
              <div class="waves wave-2"></div>
              <div class="waves wave-3"></div>
            </div>
          </span>
          Let’s see virtually how it works
          <span>Watch video</span>
        </a>
                </div>
                </>
            )}
             
            </div>


        </div>
    </section>

    <section class="row_am testimonial_section"> 
      {/* <!-- container start --> */}
      <div class="container">
        <div class="section_title aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
          {/* <!-- h2 --> */}
          <h2>What our <span>customer say</span></h2>
          {/* <!-- p --> */}
          <p>Curious about what our customers have to say? Dive into our testimonials and see why they trust us for all their phone needs. Reach out to us today and experience the difference for yourself.</p>
        </div>
        <div class="testimonial_block aos-init aos-animate" data-aos="fade-in" data-aos-duration="1500">
          <div id="testimonial_slider" class="owl-carousel owl-theme owl-loaded owl-drag">
          	{/* <!-- user 1 -->
            

            <!-- user 2 -->
            
            
            <!-- user 3 --> */}
            
          <div class="owl-stage-outer"><div class="owl-stage" style={{transform: "translate3d(-1120px, 0px, 0px)", transition: "all 2.5s ease 0s", width:"3920px"}}><div class="owl-item cloned" style={{width: "550px" ,marginright: "10px"}}><div class="item">
              <div class="testimonial_slide_box">
                <div class="rating">
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                </div>
                <p class="review">
                  “ Exceptional service! I recently purchased a phone from this shop and I couldn't be happier with my experience. The staff was incredibly knowledgeable and helped me find the perfect phone to suit my needs. ”
                </p>
                <div class="testimonial_img">
                  <img src="https://ik.imagekit.io/cy8phtesy/testimonial_user2.png?updatedAt=1715626735222" alt="image"/>
                </div>
                <h3>Willium Den</h3>
                <span class="designation">Careative inc</span>
              </div>
            </div></div><div class="owl-item cloned" style={{width: "550px", marginright: "10px"}}><div class="item">
              <div class="testimonial_slide_box">
                <div class="rating">
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                </div>
                <p class="review">
                  “ Exceptional service! I recently purchased a phone from this shop and I couldn't be happier with my experience. The staff was incredibly knowledgeable and helped me find the perfect phone to suit my needs.”
                </p>
                <div class="testimonial_img">
                  <img src="https://ik.imagekit.io/cy8phtesy/testimonial_user3.png?updatedAt=1715626748558testimonial_user3.png" alt="image"/>
                </div>
                <h3>Cyrus Stephen</h3>
                <span class="designation">Careative inc</span>
              </div>

            </div></div><div class="owl-item active" style={{width: "550px", marginright: "10px"}}><div class="item">
              <div class="testimonial_slide_box">
                <div class="rating">
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                </div>
                <p class="review">
                  “ Exceptional service! I recently purchased a phone from this shop and I couldn't be happier with my experience. The staff was incredibly knowledgeable and helped me find the perfect phone to suit my needs. ”
                </p>
                <div class="testimonial_img">
                  <img src="https://ik.imagekit.io/cy8phtesy/testimonial_user2.png?updatedAt=1715626735222" alt="image"/>
                </div>
                <h3>Shayna John</h3>
                <span class="designation">Careative inc</span>
              </div>
            </div></div><div class="owl-item" style={{width: "550px",marginright: "10px"}}><div class="item">
              <div class="testimonial_slide_box">
                <div class="rating">
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                </div>
                <p class="review">
                  “ Exceptional service! I recently purchased a phone from this shop and I couldn't be happier with my experience. The staff was incredibly knowledgeable and helped me find the perfect phone to suit my needs. ”
                </p>
                <div class="testimonial_img">
                  <img src="https://ik.imagekit.io/cy8phtesy/testimonial_user2.png?updatedAt=1715626735222" alt="image"/>
                </div>
                <h3>Willium Den</h3>
                <span class="designation">Careative inc</span>
              </div>
            </div></div><div class="owl-item" style={{width: "550px", marginright: "10px"}}><div class="item">
              <div class="testimonial_slide_box">
                <div class="rating">
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                </div>
                <p class="review">
                  “ Exceptional service! I recently purchased a phone from this shop and I couldn't be happier with my experience. The staff was incredibly knowledgeable and helped me find the perfect phone to suit my needs. ”
                </p>
                <div class="testimonial_img">
                  <img src="https://ik.imagekit.io/cy8phtesy/testimonial_user2.png?updatedAt=1715626735222" alt="image"/>
                </div>
                <h3>Cyrus Stephen</h3>
                <span class="designation">Careative inc</span>
              </div>

            </div></div><div class="owl-item cloned" style={{width: "550px", marginright: "10px"}}><div class="item">
              <div class="testimonial_slide_box">
                <div class="rating">
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                </div>
                <p class="review">
                  “ Exceptional service! I recently purchased a phone from this shop and I couldn't be happier with my experience. The staff was incredibly knowledgeable and helped me find the perfect phone to suit my needs. ”
                </p>
                <div class="testimonial_img">
                  <img src="https://ik.imagekit.io/cy8phtesy/testimonial_user2.png?updatedAt=1715626735222" alt="image"/>
                </div>
                <h3>Shayna John</h3>
                <span class="designation">Careative inc</span>
              </div>
            </div></div><div class="owl-item cloned" style={{width: "550px", marginright: "10px"}}><div class="item">
              <div class="testimonial_slide_box">
                <div class="rating">
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                  <span><i class="fa fa-star"></i></span>
                </div>
                <p class="review">
                  “ Exceptional service! I recently purchased a phone from this shop and I couldn't be happier with my experience. The staff was incredibly knowledgeable and helped me find the perfect phone to suit my needs. ”
                </p>
                <div class="testimonial_img">
                  <img src="https://ik.imagekit.io/cy8phtesy/testimonial_user2.png?updatedAt=1715626735222" alt="image"/>
                </div>
                <h3>Willium Den</h3>
                <span class="designation">Careative inc</span>
              </div>
            </div></div></div></div><div class="owl-nav disabled"><button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button><button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button></div><div class="owl-dots"><button role="button" class="owl-dot active"><span></span></button><button role="button" class="owl-dot"><span></span></button><button role="button" class="owl-dot"><span></span></button></div></div>

          {/* <!-- total review --> */}
          <div class="total_review">
            <div class="rating">
              <span><i class="fa fa-star"></i></span>
              <span><i class="fa fa-star"></i></span>
              <span><i class="fa fa-star"></i></span>
              <span><i class="fa fa-star"></i></span>
              <span><i class="fa fa-star"></i></span>
              <p>5.0 / 5.0</p>
            </div>
            <h3>2578</h3>
            <a href="">TOTAL USER REVIEWS <i class="fa-fa-right"></i></a>
          </div>

          {/* <!-- avtar faces --> */}
          <div class="avtar_faces">
            <img src="https://ik.imagekit.io/cy8phtesy/avtar_testimonial.png?updatedAt=1715626884889" alt="image"/>
          </div>
        </div>
      </div>
      {/* <!-- container end --> */}
    </section>


    {/* <!-- Download-Free-App-section-Start  --> */}
    <section class="row_am free_app_section" id="getstarted">
        {/* <!-- container start --> */}
        <div class="container">
            <div class="free_app_inner"   data-aos-duration="1500" data-aos-delay="100">
                {/* <!-- row start --> */}
                <div class="row">
                    {/* <!-- content --> */}
                    <div class="col-md-6">
                        <div class="free_text">
                            <div class="section_title">
                                <h2>Mobile App Coming Soon!
</h2>
                                <p>Stay tuned for our upcoming Android and iPhone app, making it even easier to buy, sell, and repair your devices on-the-go.</p>
                            </div>
                            <ul class="app_btn">
                                <li>
                                    <a href="#">
                              <img src="images/appstore_blue.png" alt="image" />
                             

                            </a>
                                </li>
                                <li>
                                    <a href="#">
                              <img src="images/googleplay_blue.png" alt="image" />
                            </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* <!-- images --> */}
                    <div class="col-md-6">
                        <div class="free_img ">
                        {/* <img src="https://ik.imagekit.io/cy8phtesy/iPhone%2012%20_%2012%20Pro.png?updatedAt=1715624689091"alt="image"/> */}
                        
                        <img className='m-5' src="https://ik.imagekit.io/cy8phtesy/iPhone%2012%20_%2012%20Pro.png?updatedAt=1715624689091"alt="image"/>
                            {/* <img src="images/download-screen01.png" alt="image"/>
                            <img class="mobile_mockup" src="images/download-screen02.png" alt="image"/> */}
                        </div>
                    </div>
                </div>
                {/* <!-- row end --> */}
            </div>
        </div>
        {/* <!-- container end --> */}
    </section>
    {/* <!-- Download-Free-App-section-end  --> */}

    {/* <!-- Story-Section-Start --> */}
    {/* <section class="row_am latest_story" id="blog">
        {/* <!-- container start --> */}
        {/* <div class="container">
            <div class="section_title"   data-aos-duration="1500" data-aos-delay="100">
                <h2>Read latest <span>story</span></h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typese tting <br/> indus orem Ipsum has beenthe standard dummy.</p>
            </div> */}
            {/* <!-- row start --> */}
            {/* <div class="row"> */}
                {/* <!-- story --> */}
                {/* <div class="col-md-4">
                    <div class="story_box"    data-aos-duration="1500">
                        <div class="story_img">
                            <img src="images/story01.png" alt="image"/>
                            <span>45 min ago</span>
                        </div>
                        <div class="story_text">
                            <h3>Cool features added!</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsum has.</p>
                            <a href="#">READ MORE</a>
                        </div>
                    </div>
                </div> */}

                {/* <!-- story --> */}
                {/* <div class="col-md-4">
                    <div class="story_box"    data-aos-duration="1500">
                        <div class="story_img">
                            <img src="images/story02.png" alt="image"/>
                            <span>45 min ago</span>
                        </div>
                        <div class="story_text">
                            <h3>Top rated app! Yupp.</h3>
                            <p>Simply dummy text of the printing and typesetting industry lorem Ipsum has Lorem Ipsum is.</p>
                            <a href="#">READ MORE</a>
                        </div>
                    </div>
                </div> */}

                {/* <!-- story --> */}
                {/* <div class="col-md-4">
                    <div class="story_box"    data-aos-duration="1500">
                        <div class="story_img">
                            <img src="images/story03.png" alt="image"/>
                            <span>45 min ago</span>
                        </div>
                        <div class="story_text">
                            <h3>Creative ideas on app.</h3>
                            <p>Printing and typesetting industry lorem Ipsum has Lorem simply dummy text of the.</p>
                            <a href="#">READ MORE</a>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <!-- row end --> */}
        {/* </div> */}
        {/* <!-- container end --> */}
    {/* </section>  */}
    {/* <!-- Story-Section-end --> */}

    {/* <!-- Trusted Section start --> */}
    <section class="row_am trusted_section">
        {/* <!-- container start --> */}
        <div class="container">
            <div class="section_title"    data-aos-duration="1500" data-aos-delay="100">
                {/* <!-- h2 --> */}
                <h2>Trusted by <span>150+</span> companies</h2>
                {/* <!-- p --> */}
                <p>
                For over 150 companies across diverse industries, our reliability and excellence<br/> have made us the trusted cornerstone of their operations</p>
            </div>

<div class="row">

<div class="col logo">
                            <img src="https://ik.imagekit.io/cy8phtesy/OppoLogo.png?updatedAt=1715176792580" style={{height:"80px"}} alt="image"/>
                        </div>
                        <div class="col logo">
                            <img src="https://ik.imagekit.io/cy8phtesy/SamsungLogo.png?updatedAt=1715176811378" style={{height:"80px"}} alt="image"/>
                        </div>
                        <div class="col logo">
                            <img src="https://ik.imagekit.io/cy8phtesy/SonyLogo.png?updatedAt=1715176830191" style={{height:"80px"}} alt="image"/>
                        </div>
                        {/* <div class="col logo">
                            <img src="https://ik.imagekit.io/cy8phtesy/Website%20Render%20Size.png?updatedAt=1715176429113" style={{height:"80px"}} alt="image"/>
                        </div> */}
                        <div class="col logo">
                            <img src="https://ik.imagekit.io/cy8phtesy/iPhoneLogo.png?updatedAt=1715176743062" style={{height:"80px"}} alt="image"/>
                        </div>
                        
                        


</div>
            {/* <!-- logos slider start --> */}
            <div class="company_logos">
                <div id="company_slider" class="owl-carousel owl-theme">
                    <div class="item">
                    </div>
                    <div class="item">
                        <div class="logo">
                            <img src="https://ik.imagekit.io/cy8phtesy/Website%20Render%20Size.png?updatedAt=1715176429113"alt="image"/>
                        </div>
                    </div>
                    <div class="item">
                        <div class="logo">
                            <img src="images/shopboat.png" alt="image"/>
                        </div>
                    </div>
                    <div class="item">
                        <div class="logo">
                            <img src="images/slack.png" alt="image"/>
                        </div>
                    </div>
                    <div class="item">
                        <div class="logo">
                            <img src="images/envato.png" alt="image"/>
                        </div>
                    </div>
                    <div class="item">
                        <div class="logo">
                            <img src="images/paypal.png" alt="image"/>
                        </div>
                    </div>
                    <div class="item">
                        <div class="logo">
                            <img src="images/spoty.png" alt="image"/>
                        </div>
                    </div>
                    <div class="item">
                        <div class="logo">
                            <img src="images/shopboat.png" alt="image"/>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- logos slider end --> */}
        </div>
        {/* <!-- container end --> */}
    </section>
    {/* <!-- Trusted Section ends --> */}

    {/* <!-- News-Letter-Section-Start --> */}
    <section class="newsletter_section">
        {/* <!-- container start --> */}
        <div class="container">
            <div class="newsletter_box">
                <div class="section_title"   data-aos-duration="1500" data-aos-delay="100">
                    {/* <!-- h2 --> */}
                    <h2>Subscribe newsletter</h2>
                    {/* <!-- p --> */}
                    <p>Be the first to recieve all latest post in your inbox</p>
                </div>
                <form action="" data-aos-duration="1500" data-aos-delay="100">
    <div class="form-row align-items-center">
        <div class="col-8 col-sm-10">
            <input type="email" class="form-control mb-2" placeholder="Enter your email"/>
        </div>
        <div class="col-4 col-sm-2">
            <button class="btn btn-primary">SUBMIT</button>
        </div>
    </div>
</form>

            </div>
        </div>
        {/* <!-- container end --> */}
    </section>
    {/* <!-- News-Letter-Section-end --> */}

    {/* <!-- Footer-Section start --> */}
    <footer>
        {/* <!-- section bg --> */}
        <div class="footer_bg"> <img src="images/section-bg.png" alt="image"/> </div>
        <div class="top_footer" id="contact">
            {/* <!-- container start --> */}
            <div class="container">
                {/* <!-- row start --> */}
                <div class="row">
                    {/* <!-- footer link 1 --> */}
                    <div class="col-lg-4 col-md-6 col-12">
                        <div class="abt_side">
                            <div class="logo"> <img src={IMG} alt="image"/></div>
                            <ul>
                                <li><a href="#">info@phonesplanet.co.uk</a></li>
                                <li>Unit 1b Grand Union Way, Southall  UB2 4EX </li>
                                {/* <li><a href="#">+1-900-123 0000</a></li> */}
                            </ul>
                            <ul class="social_media">
                                <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                <li><a href="#"><i class="fa fa-pinterest"></i></a></li>
                            </ul>
                        </div>
                    </div>

                    {/* <!-- footer link 2 --> */}
                    <div class="col-lg-3 col-md-6 col-12">
                        
                    </div>

                    {/* <!-- footer link 3 --> */}
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="links">
                            <h3> Suport Links</h3>
                            <ul>
                            <li><a href="/">Home</a></li>
                                <li> <a  href="#features">Features</a></li>
                                <li> <a  href="#how_it_work">About Us</a></li>
                                <li><a href="#services">Services</a></li>
                                {/* <li><a href="#">Blog</a></li> */}
                                <li><a href="/Contactus">Contact us</a></li>
                            </ul>
                        </div>
                    </div>

                    {/* <!-- footer link 4 --> */}
                    <div class="col-lg-2 col-md-6 col-12">
                        <div class="try_out">
                            <h3>Coming soon</h3>
                            <ul class="app_btn">
                                <li>
                                    <a href="">
                          <img src="https://ik.imagekit.io/cy8phtesy/appstore_blue.png?updatedAt=1715177641650" alt="image" />
                        </a>
                                </li>
                                <li>
                                    <a href="">
                          <img src="https://ik.imagekit.io/cy8phtesy/googleplay_blue.png?updatedAt=1715177694930" alt="image" />
                        </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <!-- row end --> */}
            </div>
            {/* <!-- container end --> */}
        </div>

        {/* <!-- last footer --> */}
        <div class="bottom_footer">
            {/* <!-- container start --> */}
            <div class="container">
                {/* <!-- row start --> */}
                <div class="row">
                    <div class="col-md-6">
                        <p>© Copyrights 2024. All rights reserved.</p>
                    </div>
                    <div class="col-md-6">
                        <p class="developer_text">Design & developed by <b href="" target="blank">Powered by Soft Access (SA) LTD</b></p>
                    </div>
                </div>
                {/* <!-- row end --> */}
            </div>
            {/* <!-- container end --> */}
        </div>

        {/* <!-- go top button --> */}
        <div class="go_top">
            <span><img src="images/go_top.png" alt="image" /></span>
        </div>
    </footer>
    {/* <!-- Footer-Section end --> */}

    {/* <!-- VIDEO MODAL --> */}
    <div class="modal fade youtube-video" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <button id="close-video" type="button" class="button btn btn-default text-right" data-dismiss="modal">
        <i class="fa fa-close"></i>
      </button>
                <div class="modal-body">
                    <div id="video-container" class="video-container">
                        <iframe id="youtubevideo" src="" width="640" height="360" frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>
                <div class="modal-footer">
                </div>
            </div>
        </div>
    </div>

    <div class="purple_backdrop"></div>

</div>
</body>
   
    </>
   
  );
}

export default Home;
