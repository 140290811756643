import React from 'react'
import Navbar from '../Navbar/Navbar'

import './Contactus.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FaFacebook } from 'react-icons/fa';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
// import Footer from '../Footer'

function Contactus() {
  return (
   <>
 <div>
    <div>
    <Navbar/>
    </div>
    <div>
    <body>

<div class="containerr ">

  {/* <span class="big-circle"></span> */}
  <img src="img/shape.png" class="square" alt="" />
  <div class="form">
    <div class="contact-info">
      <h3 class="title">Let's get in touch</h3>
      <p class="text">
      Have a query or need assistance? Drop us a message below.

We're eager to hear from you! Reach out and let's start a conversation.
      </p>

      <div class="info ">
        <div class="information " >
        <div className='mb-3'>  <FontAwesomeIcon icon={faMapMarkerAlt} /> &nbsp; &nbsp;</div>
       

          <p>Unit 1b Grand Union Way, Southall  UB2 4EX </p>
        </div>
        <div class="information">
        <div className='mb-3'> <FontAwesomeIcon icon={faEnvelope} />&nbsp; &nbsp;</div>
       
          <p>info@phonesplanet.co.uk</p>
        </div>
        <div class="information">
        <div className='mb-3'> <FontAwesomeIcon icon={faPhone} />&nbsp;&nbsp;</div>
       
        {/* <p>+1-900-123 0000</p> */}

        </div>
      </div>

      <div class="social-media">
        <p >Connect with us :</p>
        <div class="social-icons">
          <a href="#">
          <FaFacebook style={{color:"#32236F"}}/>
          </a>
          <a href="#">
          <FontAwesomeIcon style={{color:"#32236F"}} icon={faTwitter} />
          </a>
          <a href="#">
          <FontAwesomeIcon  style={{color:"#32236F"}}icon={faInstagram} />
          </a>
          <a href="#">
          <FontAwesomeIcon style={{color:"#32236F"}} icon={faLinkedinIn} />
          </a>
        </div>
      </div>
    </div>

    <div class="contact-form">
      <span class="circle one"></span>
      <span class="circle two"></span>

      <form action="index.html" autocomplete="off">
        <h3 class="title ">Contact us</h3>
        <div class="input-container" style={{color:"white"}}>
          <input type="text" name="name" class="input" />
          <label for="">Username</label>
         
        </div>
        <div class="input-container">
          <input type="email" name="email" class="input" />
          <label  style={{color:"white"}}for="">Email</label>
       
        </div>
        <div class="input-container">
          <input type="tel" name="phone" class="input" />
          <label  style={{color:"white"}}for="">Phone</label>
          
        </div>
        <div class="input-container textarea">
          <textarea name="message" class="input"></textarea>
          <label style={{color:"white"}} for="">Message</label>
          
        </div>
        <input type="submit" value="Send" class="btnn" />
      </form>
    </div>
  </div>
</div>

<script src="app.js"></script>
</body>
    </div>
 </div>
<div>



</div>






   </>
  )
}

export default Contactus